<template>

  <li class="filter-choose" tabindex="-1">
    <div class="form-label" v-if="showCheckBoxAndLabel">

      <checkbox class="form-check form-check-inline" :name="`f-${id}-${filter.field_type_id}`" :labelText="filter.label" :initialValue="checkIfExist(parentMultipleSelects,filter.label)" @checkBoxChanged="selectValue(parentMultipleSelects,parentFilterId,filter.label,'parent')"/>   

      <span class="material-icons expand-collapse-btn" tabindex="0" @keypress.space.prevent @keyup.enter="collapseChildDropdown" @keyup.space="collapseChildDropdown" @click="collapseChildDropdown" aria-hidden="true">{{ !isCollapsed ? 'expand_less' : 'expand_more' }}</span>
    </div>

    <div v-if="filter.type=='depender'" v-show="!isCollapsed">

      <ContentFilterOptions :style="theStyle" v-for="option in filterOptions" :selectedFilters="selectedFilters" :parentMultipleSelects="parentMultipleSelects" :parentFilterId="filter.field_type_id" :level="nextLevel" :filter="option" @selectionChanged="childSelectionChanged"/>

    </div>

    <div v-else v-show="!isCollapsed">

      <ul class="filter-choose-item-submenu mt20">
        <li v-for="(option,index) in filterOptions" :key="option.field_type_id"  class="filter-choose-item-submenu-item">

          <checkbox :name="`f-${id}-${index}`" :labelText="option.label" :initialValue="checkIfExist(multipleSelects,option.label)" @checkBoxChanged="selectValue(multipleSelects,filter.field_type_id,option.label)"/>    

        </li>
      </ul>

    </div>

  </li>

</template>

<script>
  import { createUniqueId } from '@/utils/wb-helper';

  export default {
    name: 'ContentFilterOptions',
    components: {
      Checkbox: () => import('@/components/controls/Checkbox.vue'),
      ContentFilterOptions: () => import('./ContentFilterOptions'),
    },
    props: {
      parentFilterId: {
        default: null,
      },
      filter: {
        type: Object,
        default: null,
      },
      level: {
        type: Number,
        default: 0,
      },
      showCheckBoxAndLabel: {
        type: Boolean,
        default: true,
      },
      parentMultipleSelects: {
        type: Array,
        default() {
          return [];
        }
      },
      selectedFilters: {
        type: Map,
        default() {
          return new Map();
        }
      }
    },
    data() {
      return {
        multipleSelects: [],
        id: createUniqueId(),
        isCollapsed: false,
      }
    },
    watch: {
      filterSelected(vals) {
        this.$emit('filters-changed', vals);
      }
    },
    mounted() {
      /*trying to set preset values*/
      if(this.selectedFilters!==null){
        var res = this.selectedFilters.get(this.filter.field_type_id);
        if(res!==null && res !== undefined){
          this.multipleSelects = res;
        }

        res = this.selectedFilters.get(this.parentFilterId);
        if(res!==null && res !== undefined){
          this.parentMultipleSelects = res;
          this.checkParentSelection();
        }
      }

      if(this.level>1){
        this.isCollapsed = true;
      }
    },
    computed: {
      filterOptions() {
        if(this.filter!==null)
          return this.filter.options;
      },
      nextLevel() {
        return this.level+1;
      },
      theStyle(){
        var padding = this.level*20;
        return "padding-left: "+padding+"px; margin-top:20px;";
      }
    },
    methods: {
      collapseChildDropdown(){
        this.isCollapsed=!this.isCollapsed;
      },
      checkIfExist: function(array,value) {
        let index = array.indexOf(value);
        return index >= 0 ? true : false;
      },
      selectValue: function(array,field_type_id,value,from="child") {
        if (!this.checkIfExist(array,value)) {
          array.push(value);
        } else {
          let index = array.indexOf(value);
          array.splice(index, 1);
        }

        if(from=="parent"){
          this.checkParentSelection();
        }
        else{
          this.checkChildSelection();
        }

        if(this.parentFilterId!==null){
          this.$emit('parentSelectionChanged', this.parentFilterId, this.parentMultipleSelects);
        }
        
        this.$emit('selectionChanged', this.filter.field_type_id, this.multipleSelects);
      },
      checkParentSelection(){
        /*if the parent-Element is set, there needs to be at least one child element set*/
        if(this.checkIfExist(this.parentMultipleSelects,this.filter.label)){
          /*this condition is met when this.multipleSelects is longer than 0*/
          if(this.multipleSelects.length>0){
            /*do nada*/
          }
          else{
            /*if there are no child-elements set, we'll set them all*/
            for(var i = 0; i< this.filterOptions.length; i++){
              this.multipleSelects.push(this.filterOptions[i].label);
            }
          }
        }
        else{
          /*if the parent-value for this block is not set, we'll unset all the childs*/
          this.multipleSelects = [];
        }
      },
      checkChildSelection(){
        /*check if one of the child-Elements is set*/
        if(this.multipleSelects.length>0){
          /*make sure that the parent value is set*/
          if(!this.checkIfExist(this.parentMultipleSelects,this.filter.label)){
            this.parentMultipleSelects.push(this.filter.label);
          }
        }
        else{
         /*if none of the child-elements are set, the parent element has to be empty as well*/ 
         this.parentMultipleSelects=[];
       }
     },
     childSelectionChanged(field_type_id,multipleSelects){
      /*we'll just pass everything on to the element that displays all of these recursive elements. There we'll handle everything*/
      if(field_type_id!==null){
        this.$emit('selectionChanged', field_type_id, multipleSelects);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .filter-choose {
    overflow-y: auto;

    .form-label{
      justify-content: space-between;
      display: flex;
    }

    .expand-collapse-btn{
      cursor: pointer;
    }

    &-item {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom:10px;

      &:first-child{
        padding-top: 0px;
      }

      &.info {
        align-items: flex-start;
        flex-direction: column;

        p {
          margin-left: 40px;
          margin-top: 5px;
          font-size: 12px;
          color: $text-light;
        }
      }

      &.has-options {
        &::after {
          position: absolute;
          font-family: 'Material Icons';
          font-size: 24px;
          content: '\e5cf';
          top: -7px;
          right: 0;
        }
      }

      label {
        color: $black-color;
      }

      &-submenu {
        margin-top: 25px;
        margin-left: 20px;
        overflow-x: hidden;

        &-item {
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }



</style>